import { gql } from '@apollo/client/core';

// saved properties

export const CREATE_SAVED_PROPERTY = gql`
  mutation createSavedProperty(
    $user_id: uuid
    $property_id: String
    $notifications: json
  ) {
    insert_properties_saved_one(
      object: {
        user_id: $user_id
        property_id: $property_id
        notifications: $notifications
      }
    ) {
      id
      user_id
      property_id
      notifications
    }
  }
`;

export const UPDATE_SAVED_PROPERTY_BY_ID = gql`
  mutation updateSavedPropertyById(
    $property_id: String = ""
    $notifications: json = ""
  ) {
    update_properties_saved(
      where: { property_id: { _eq: $property_id } }
      _set: { notifications: $notifications }
    ) {
      returning {
        id
        user_id
        property_id
        notifications
      }
    }
  }
`;

// TODO: include pagination
export const GET_SAVED_PROPERTIES_BY_USER = gql`
  query getSavedPropertiesByUser {
    properties_saved {
      id
      user_id
      property_id
      notifications
    }
  }
`;

export const GET_SAVED_PROPERTIES_BY_USER_IN = gql`
  query getSavedPropertiesByUserIn($property_ids: [String!]) {
    properties_saved(where: { property_id: { _in: $property_ids } }) {
      id
      user_id
      property_id
      notifications
    }
  }
`;

export const DELETE_SAVED_PROPERTY_BY_MLS_ID = gql`
  mutation deleteSavedPropertyByMlsId($property_id: String) {
    delete_properties_saved(where: { property_id: { _eq: $property_id } }) {
      affected_rows
    }
  }
`;

// saved searches

export const CREATE_SAVED_SEARCH = gql`
  mutation createSavedSearch(
    $user_id: uuid
    $name: String
    $params: String
    $params_search: String
  ) {
    insert_searches_saved_one(
      object: {
        user_id: $user_id
        name: $name
        params: $params
        params_search: $params_search
      }
    ) {
      id
      user_id
      name
      params
      notifications
    }
  }
`;

export const UPDATE_SAVED_SEARCH_BY_ID = gql`
  mutation updateSavedSearchById(
    $id: uuid!
    $input: searches_saved_set_input!
  ) {
    update_searches_saved_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      user_id
      name
      params
      notifications
    }
  }
`;

export const GET_SAVED_SEARCH_BY_ID = gql`
  query getSavedSearchById($id: uuid) {
    searches_saved_by_pk(id: $id) {
      id
      user_id
      name
      params
      notifications
    }
  }
`;

export const GET_SAVED_SEARCHES_BY_USER = gql`
  query getSavedSearches($limit: Int = 10, $offset: Int = 0) {
    searches_saved(limit: $limit, offset: $offset) {
      id
      user_id
      name
      params
      notifications
    }
    searches_saved_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const DELETE_SAVED_SEARCH_BY_ID = gql`
  mutation deleteSavedSearchById($id: uuid!) {
    delete_searches_saved_by_pk(id: $id) {
      id
    }
  }
`;

// notifications settings

export const UPDATE_NOTIFICATIONS = gql`
  mutation SetNotifications($user_id: uuid, $options: json = "") {
    insert_notifications_one(
      object: { options: $options }
      on_conflict: {
        update_columns: options
        where: { user_id: { _eq: $user_id } }
        constraint: notifications_user_id_key
      }
    ) {
      id
      user_id
      options
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($user_id: uuid) {
    notifications(where: { user_id: { _eq: $user_id } }) {
      id
      user_id
      options
    }
  }
`;

// WP

export const GET_WP_EVENTS = gql`
  query GetEvents($language: String = "es") {
    events: eventos(
      first: 30
      where: { status: PUBLISH, language: $language, eventTypeSlug: "default" }
    ) {
      nodes {
        id
        title
        link
        eventTypes: tiposDeEvento {
          nodes {
            name
          }
        }
        fields: ACF_Eventos {
          subTitle: subtitulo
          speaker
          speakerPosition
          description
          address: direccion
          datetime: horario
          linkTo
          region
          portalId: portalid
          formId: formid
          cta: buttoncta
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;

export const GET_WP_PROJECTS = gql`
  query GetProjects {
    projects: newProjects(
      first: 15
      where: { orderby: { order: ASC, field: MENU_ORDER } }
    ) {
      nodes {
        id
        title
        link
        fields: ACF_NewProjects {
          location: direccion {
            city
            stateShort
          }
          logoImage: logotipoDelProyecto {
            sourceUrl
            altText
          }
          images: galeria {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`;

export const GET_WP_POSTS_HOME = gql`
  query GetPosts($language: String = "es") {
    posts(first: 15, where: { status: PUBLISH, language: $language }) {
      nodes {
        id
        date
        slug
        categories(where: { childless: true }) {
          nodes {
            slug
            parent {
              node {
                slug
              }
            }
          }
        }
        title
        excerpt
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;

export const GET_WP_POSTS = gql`
  query GetPosts(
    $language: String = "es"
    $perPage: Int = 12
    $offset: Int = 0
  ) {
    posts(
      where: {
        status: PUBLISH
        language: $language
        offsetPagination: { size: $perPage, offset: $offset }
      }
    ) {
      pageInfo {
        offsetPagination {
          total
        }
      }
      nodes {
        id
        date
        link
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        categories(where: { childless: true }) {
          nodes {
            name
            slug
            parent {
              node {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WP_POSTS_BY_SEARCH = gql`
  query SearchPosts(
    $language: String = "es"
    $perPage: Int = 12
    $offset: Int = 0
    $search: String = ""
  ) {
    posts(
      where: {
        status: PUBLISH
        language: $language
        offsetPagination: { size: $perPage, offset: $offset }
        search: $search
      }
    ) {
      pageInfo {
        offsetPagination {
          total
        }
      }
      nodes {
        id
        date
        link
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        categories(where: { childless: true }) {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;

export const GET_WP_POSTS_BY_CATEGORY = gql`
  query GetPosts(
    $language: String = "es"
    $perPage: Int = 12
    $offset: Int = 0
    $categorySlug: String
    $categoryId: ID!
  ) {
    posts(
      where: {
        status: PUBLISH
        language: $language
        categoryName: $categorySlug
        offsetPagination: { size: $perPage, offset: $offset }
      }
    ) {
      pageInfo {
        offsetPagination {
          total
        }
      }
      nodes {
        id
        date
        link
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        categories(where: { childless: true }) {
          nodes {
            name
            slug
            parent {
              node {
                name
                slug
              }
            }
          }
        }
      }
    }
    category(id: $categoryId, idType: SLUG) {
      name
      slug
      translations {
        slug
      }
      parent {
        node {
          name
          slug
          translations {
            slug
          }
        }
      }
      seo {
        title
        description: opengraphDescription
        fullHead
      }
    }
  }
`;

export const GET_WP_POST = gql`
  query GetPost($slug: String, $language: String = "es") {
    posts(where: { status: PUBLISH, nameIn: [$slug], language: $language }) {
      nodes {
        id
        date
        link
        title
        slug
        content
        categories(where: { childless: true }) {
          nodes {
            name
            slug
            translations {
              slug
            }
            parent {
              node {
                name
                slug
                translations {
                  slug
                }
              }
            }
          }
        }
        tags {
          nodes {
            id
            name
            slug
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        seo {
          title
          description: opengraphDescription
          fullHead
          schema {
            raw
            pageType
          }
        }
        translations {
          slug
          uri
        }
        previousPost {
          slug
          title
          categories(where: { childless: true }) {
            nodes {
              name
              slug
            }
          }
        }
        nextPost {
          slug
          title
          categories(where: { childless: true }) {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    sidebarBanners(where: { language: $language, status: PUBLISH }) {
      nodes {
        title
        fields: ACF_sidebar_banners {
          cta: bannerCta
          ctaUrl: bannerCtaUrl
          description: bannerDescription
          image: bannerImage {
            sourceUrl
            altText
          }
        }
      }
    }
    recentPosts: posts(
      where: {
        status: PUBLISH
        language: $language
        offsetPagination: { size: 3, offset: 0 }
      }
    ) {
      nodes {
        title
        slug
        categories(where: { childless: true }) {
          nodes {
            slug
          }
        }
      }
    }
  }
`;

export const GET_WP_RELATED_POSTS = gql`
  query GetPost($currentPostId: ID, $language: String = "es", $tagIds: [ID]) {
    posts(
      where: {
        status: PUBLISH
        tagIn: $tagIds
        language: $language
        offsetPagination: { size: 6 }
        notIn: [$currentPostId]
      }
    ) {
      nodes {
        id
        date
        link
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        categories(where: { childless: true }) {
          nodes {
            name
            slug
            parent {
              node {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WP_COUNTIES = gql`
  query Counties($first: Int = 100, $after: String) {
    counties(first: $first, after: $after, where: { status: PUBLISH }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        title
        content
        featuredImage {
          node {
            srcSet
            sourceUrl
          }
        }
        relations: ACF_Counties_Relations {
          cities {
            ... on Ciudad {
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_WP_CITIES = gql`
  query Counties($county: String, $first: Int = 100, $after: String) {
    counties(
      first: $first
      after: $after
      where: { status: PUBLISH, title: $county }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        title
        content
        featuredImage {
          node {
            srcSet
            sourceUrl
          }
        }
        relations: ACF_Counties_Relations {
          cities {
            ... on Ciudad {
              title
              featuredImage {
                node {
                  srcSet
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WP_NEIGHBORHOODS = gql`
  query Neighborhoods($city: String, $first: Int = 100, $after: String) {
    cities: ciudades(
      first: $first
      after: $after
      where: { status: PUBLISH, title: $city }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        title
        relations: ACF_Cities_Relations {
          county {
            ... on County {
              title
            }
          }
          neighborhoods {
            ... on Neighborhood {
              title
              featuredImage {
                node {
                  srcSet
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WP_TESTIMONIALS = gql`
  query Testimonials($language: String = "es") {
    testimonials(
      where: { language: $language, orderby: { field: MENU_ORDER, order: ASC } }
    ) {
      nodes {
        id
        title
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        fields: ACF_testimonials {
          clientName
          description
          country
          comment
          video
        }
      }
    }
  }
`;

export const GET_WP_FEATURED_PROJECTS_ORLANDO = gql`
  query ProjectsOrlando {
    home: homeOptions {
      fields: homeACF {
        orlandoProjects {
          ... on NewProject {
            title
            databaseId
          }
        }
      }
    }
  }
`;

export const GET_WP_ELITE_PAGE = gql`
  query PFSClub($language: String = "es") {
    pfsClub {
      benefits: Benefits {
        benefit {
          image {
            sourceUrl
          }
          title
        }
      }
    }
    eliteEvents: eventos(
      first: 50
      where: {
        status: PUBLISH
        language: $language
        eventTypeSlug: "evento-para-afiliado"
      }
    ) {
      nodes {
        id
        title
        link
        eventType: tiposDeEvento {
          __typename
          nodes {
            name
          }
        }
        eventsCategories {
          nodes {
            name
            slug
          }
        }
        fields: ACF_Eventos {
          subTitle: subtitulo
          speaker
          speakerPosition
          description
          address: direccion
          datetime: horario
          linkTo
          region
          portalId: portalid
          formId: formid
          cta: buttoncta
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
    pfsEvents: eventos(
      first: 50
      where: { status: PUBLISH, language: $language, eventTypeSlug: "default" }
    ) {
      nodes {
        id
        title
        link
        eventType: tiposDeEvento {
          __typename
          nodes {
            name
          }
        }
        eventsCategories {
          nodes {
            name
            slug
          }
        }
        fields: ACF_Eventos {
          cityLabel: shortCity
          subTitle: subtitulo
          speaker
          speakerPosition
          description
          address: direccion
          datetime: horario
          linkTo
          region
          portalId: portalid
          formId: formid
          cta: buttoncta
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;

// ANALYTICS

/*
 * @param $filter: MarketReportLatestUpdatedPropertiesWhereInput
 * To filter the data by building name, city, county, etc.
 * Example:
    {
        "filter": {
            "county": {
                "equals": "Miami-Dade County"
            }
        }
    }
 */
function subTypeAnalytics(subType: string, rented: boolean = false) {
  return `${subType}${rented ? '_rented' : ''}: cube(
    limit: 5000
    where: {
      AND: [
        { market_report_latest_updated_properties: $filters }
        {
          market_report_latest_updated_properties: {
            sub_type: { equals: "${subType}" }
            ${rented ? 'type: { equals: "RNT" }' : 'type: { equals: "RES" }'}
            status: { equals: "Active" }
          }
        }
      ]
    }
  ) {
    market_report_latest_updated_properties {
      count
      avg_list_price
    }
  }`;
}

export const GET_ANALYTICS_REPORT = gql`
  query CubeQuery($filters: MarketReportLatestUpdatedPropertiesWhereInput) {
    ${subTypeAnalytics('singleFamilyResidence')}
    ${subTypeAnalytics('singleFamilyResidence', true)}
    ${subTypeAnalytics('townhouse')}
    ${subTypeAnalytics('townhouse', true)}
    ${subTypeAnalytics('villa')}
    ${subTypeAnalytics('villa', true)}
    ${subTypeAnalytics('condo')}
    ${subTypeAnalytics('condo', true)}
    ${subTypeAnalytics('multiFamily')}
    ${subTypeAnalytics('multiFamily', true)}
  }
`;

// TODO: Include close_date: { inDateRange: "last 2 years" }

export const GET_ANALYTICS_PRICE_TRENDS = gql`
  query CubeQuery($filters: MarketReportLatestUpdatedPropertiesWhereInput) {
    sales: cube(
      limit: 5000
      where: {
        AND: [
          { market_report_latest_updated_properties: $filters }
          {
            market_report_latest_updated_properties: {
              type: { equals: "RES" }
              status: { equals: "Closed" }
            }
          }
        ]
      }
    ) {
      market_report_latest_updated_properties(orderBy: { close_date: asc }) {
        avg_close_price
        type
        close_date {
          month
        }
      }
    }
    rentals: cube(
      limit: 5000
      where: {
        AND: [
          { market_report_latest_updated_properties: $filters }
          {
            market_report_latest_updated_properties: {
              type: { equals: "RNT" }
              status: { in: ["Closed", "Rented"] }
            }
          }
        ]
      }
    ) {
      market_report_latest_updated_properties(orderBy: { close_date: asc }) {
        avg_close_price
        type
        close_date {
          month
        }
      }
    }
  }
`;

// sales_price_sqft_in_time_range
export const GET_ANALYTICS_SALES = gql`
  query ANALYTICS_SALES($buildingName: String!) {
    current: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          type: { equals: "RES" }
        }
      }
    ) {
      latest_updated_properties {
        building_name
        count
        max_list_price_sqft
        min_list_price_sqft
        average_list_price_sqft
      }
    }
    period_3_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last 3 months" }
          type: { equals: "RES" }
        }
      }
    ) {
      latest_updated_properties {
        count
        max_list_price_sqft
        min_list_price_sqft
        average_list_price_sqft
      }
    }
    period_6_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last 6 months" }
          type: { equals: "RES" }
        }
      }
    ) {
      latest_updated_properties {
        count
        max_list_price_sqft
        min_list_price_sqft
        average_list_price_sqft
      }
    }
    period_12_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last year" }
          type: { equals: "RES" }
        }
      }
    ) {
      latest_updated_properties {
        count
        max_list_price_sqft
        min_list_price_sqft
        average_list_price_sqft
      }
    }
    period_24_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last 2 years" }
          type: { equals: "RES" }
        }
      }
    ) {
      latest_updated_properties {
        count
        max_list_price_sqft
        min_list_price_sqft
        average_list_price_sqft
      }
    }
  }
`;

export const GET_ANALYTICS_RENTAL = gql`
  query ANALYTICS_RENTAL($buildingName: String!) {
    current: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          type: { equals: "RNT" }
        }
      }
    ) {
      latest_updated_properties {
        building_name
        count
        average_list_price_sqft
      }
    }
    period_3_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last 3 months" }
          type: { equals: "RNT" }
        }
      }
    ) {
      latest_updated_properties {
        count
      }
    }
    period_6_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last 6 months" }
          type: { equals: "RNT" }
        }
      }
    ) {
      latest_updated_properties {
        count
      }
    }
    period_12_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last year" }
          type: { equals: "RNT" }
        }
      }
    ) {
      latest_updated_properties {
        count
      }
    }
    period_24_months: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          close_date: { inDateRange: "last 2 years" }
          type: { equals: "RNT" }
        }
      }
    ) {
      latest_updated_properties {
        count
      }
    }
  }
`;

export const GET_ANALYTICS_AVG_SALES_PRICE_SQFT = gql`
  query SALES_PRICE($buildingName: String!, $buyType: String!) {
    sold: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          type: { equals: $buyType }
          close_date: { inDateRange: "last 5 years" }
        }
      }
    ) {
      latest_updated_properties {
        count
        building_name
        average_sales_price_sqft
        average_list_price_sqft
        close_date {
          value
        }
      }
    }
    soldByMonth: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          type: { equals: $buyType }
          close_date: { inDateRange: "last 5 years" }
        }
      }
    ) {
      latest_updated_properties {
        count
        building_name
        average_sales_price_sqft
        average_list_price_sqft
        close_date {
          month
        }
      }
    }
    soldByYear: cube(
      limit: 5000
      where: {
        latest_updated_properties: {
          building_name: { equals: $buildingName }
          status: { notIn: "Active" }
          close_price: { gt: 0 }
          type: { equals: $buyType }
          close_date: { inDateRange: "last 5 years" }
        }
      }
    ) {
      latest_updated_properties {
        count
        average_sales_price_sqft
        close_date {
          year
        }
      }
    }
  }
`;

// WP

export const GET_WP_PAGES = gql`
  query GetPages(
    $language: String = "es"
    $perPage: Int = 12
    $offset: Int = 0
  ) {
    pages: institutionals(
      where: {
        status: PUBLISH
        language: $language
        offsetPagination: { size: $perPage, offset: $offset }
      }
    ) {
      pageInfo {
        offsetPagination {
          total
        }
      }
      nodes {
        id
        date
        link
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;

export const GET_WP_PAGE = gql`
  query GetPage($slug: String, $language: String = "es") {
    pages: institutionals(
      where: { status: PUBLISH, nameIn: [$slug], language: $language }
    ) {
      nodes {
        nextPage {
          slug
          title
        }
        previousPage {
          slug
          title
        }
        id
        date
        link
        title
        guid
        date
        content
        elementorContent
        slug
        pilarPage {
          isPillarPage
          pillarPageData {
            heroTitle
            heroSubtitle
            heroImage {
              altText
              sourceUrl
            }
            initialParagraph
            image1 {
              altText
              sourceUrl
            }
            cta1
            ctaAnchor1
            subTitle1
            paragraph1
            subTitle2
            paragraph2
            video2
            cta2
            subTitle3
            paragraph3
            image4 {
              altText
              sourceUrl
            }
            subTitle4
            paragraph4
            subTitle5
            buildingsId
            embedCode
            faqTitle
            faqQuestions {
              faqQuestionTitle
              faqAnwser
            }
            relatedPost {
              ... on Post {
                id
                date
                link
                title
                excerpt
                slug
                featuredImage {
                  node {
                    altText
                    sourceUrl
                  }
                }
                categories(where: { childless: true }) {
                  nodes {
                    name
                    slug
                    parent {
                      node {
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seo {
          title
          description: opengraphDescription
          fullHead
          schema {
            raw
            pageType
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        translations {
          slug
          title
        }
      }
    }
    sidebarBanners(where: { language: $language, status: PUBLISH }) {
      nodes {
        title
        fields: ACF_sidebar_banners {
          cta: bannerCta
          ctaUrl: bannerCtaUrl
          description: bannerDescription
          image: bannerImage {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`;
